import React from 'react';

export default ({children,icon})=>(
  <header className="text-center mt-24 mb-10">
    {
      icon && (
        <p>
          <i className={"text-2xl text-gray-500 " + icon}></i>
        </p>
      )
    }
    
    <h2 className="title text-center text-4xl subtitle font-bold uppercase"> {children} </h2>
  </header>
)