import React from 'react';
import { Slide } from 'react-slideshow-image';

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
}

export default ({images}) => {
  return (
    <div className="slide-container">
      <Slide {...properties}>
        {
          images.map((image, i)=>(
            <div className="each-slide" key={i}>
              <div style={{ 'backgroundImage': `url(${image})`, height: '600px' }} className="bg-cover bg-center"></div>
            </div>
          ))
        }
      </Slide>
    </div>
  )
}