import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ImageModal from './ImageModal';
import useCollapse from 'react-collapsed';
import Title from './Title';

export default (props) => {
  const { getCollapseProps, getToggleProps, isOpen } = useCollapse();
  const data = useStaticQuery(graphql`
    {
      albumsJson(slug: {eq: "historia"}) {
        photos
        folder
      }
    }
  `);
  const album = data.albumsJson;
  const photos = album.photos;
  
  return(
    <div>
      <Title icon="fas fa-book-open ">
        Nuestra historia
      </Title>
      <div className="text-center text-xl">
        <button {...getToggleProps()}>
          {isOpen ? <span className="mr-3">Ver menos</span>  : <span className="mr-3">Ver más</span>}
          {isOpen ? "▲" : "▼"}
        </button>
      </div>

     
      <div {...getCollapseProps()} className="mt-12">
        <div className="md:flex items-center">
          <div className="flex-1 mr-4">
            <p >El laboratorio de Análisis Clínicos “Diagnosur” se fundó el 1º de Febrero de 1991, según la Inscripción ante la Secretaria de Hacienda y Crédito Público con el Registro GOCJ 621227 RDO con domicilio en la 6ª poniente norte No. 320 en Tuxtla Gutiérrez, Chiapas, México. Siendo su Fundador el Q.F.B. José González Correa.</p>

            <p >El incremento de trabajo nos obligó a conseguir equipos de laboratorio más actualizados con tecnología de punta para poder hacer frente a la demanda de trabajo, de igual manera a contratar personal calificado y adquirir instalaciones más amplias; en base a lo anterior el 11 de enero del 2002,  realizamos el cambio de domicilio a la 6ª poniente norte No. 217 en esta misma Ciudad, donde contamos con instalaciones propias. </p>

            <p >Hago mención que contamos con  registro de marca Diagnosur con número 802233 ante la Secretaria de Desarrollo Económico con fecha 29 de abril del 2003. </p>

            <p>El 05 de Mayo del año 2009 se realizo un cambio ante la SHCP englobando todas las sucursales en una Sociedad Anónima denominada Diagnosur Diagnósticos S.A. de C.V., según el  R.F.C.  DDI0905053M9 y según la Secretaria de Relaciones Exteriores bajo el permiso 4402293, expediente 20094402196 y folio 090430441003.</p>
          </div>
          <div className="w-1/2">
            <ImageModal src={require(`../images/recuerdo/DSC04576.jpg`)} />
          </div>
        </div>    
        <h3 className="font-bold uppercase text-2xl mb-4">Fotos</h3>
        <div className="flex flex-wrap items-center">
          {
            photos.map((el, i) => (
              <div className="w-full sm:w-1/2 md:w-1/4 m-4" key={i}>
                <ImageModal src={require(`../images/${album.folder}/${el}`)} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}