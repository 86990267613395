import React from 'react';
import Layout from '../components/layout';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
import Title from '../components/Title';
import ImageModal from '../components/ImageModal';
import Slide from '../components/Slide';
import CollapsableHistory from '../components/CollapsableHistory';
import Certificaciones from '../components/Certificaciones';

export default ()=>(
  <Layout>
    <PageHeader>
      <h2 className="text-6xl">Acerca de DIAGNOSUR</h2>
      <p className="mt-2 text-sm">
        <Link to="/" className="underline">Inicio</Link>
        <i className="fas fa-chevron-right mx-2"></i>
        <Link to="/acercade" className="underline">Acerca de</Link>
      </p>
    </PageHeader>
    <Container className="md:px-0 px-6 mb-12" hideAside={true}>
      <div className="text-content mt-8">
        <Slide images={[1, 2, 3, 4, 5].map((index) => require(`../images/about-slides/${index}.jpg`))} />        
        
        <Title icon="fas fa-flask ">Misión</Title>
        <div className="md:flex items-center">
          <ImageModal src={require(`../images/modern/proxyclick.jpg`)} style={{height:"400px"}} />
          <div className="ml-6 flex-1 mt-6 md:mt-0">
            <p className="text-2xl italic">Seguir siendo uno de los laboratorios de análisis clínicos con personal calificado con la experiencia y disciplina en el área, con capacidad de atención desde el momento de la recepción del paciente hasta el momento de la entrega de resultados, con el compromiso de brindarle rapidez, veracidad y calidad para que el paciente obtenga resultados con la seguridad que coadyuvaran en su diagnostico, monitoreo y prevención de enfermedades.</p>
          </div>
        </div>

        <Title icon="fas fa-eye ">Visión</Title>
        <p className="text-center text-2xl italic">Ser uno de los Laboratorios de Análisis Clínicos líderes en el mercado con cobertura en el  Estado de Chiapas, con  equipo y  tecnología de punta para la reducción de tiempos de procesos; buscando la mejora continua.</p>

        <Title icon="fas fa-check-double ">Objetivos</Title>
        <ul className="flex flex-wrap justify-center text-center list-none">
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de un mapa y una pincheta" src={require("../images/icons/map-location.png")} style={{height:"90px"}} className="inline-block" />
            <p className="mt-2">Tener cobertura en la mayor parte de los Municipios en el Estado de Chiapas.</p>
          </li>
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de un reconocimiento" src={require("../images/icons/product.png")} style={{ height: "90px" }} className="inline-block" />
            <p className="mt-2">Contar con personal calificado en constante capacitación.</p>
          </li>
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de un equipo de análisis" src={require("../images/icons/diagnostic-tool.png")} style={{ height: "90px" }} className="inline-block" />
            <p className="mt-2">Contar con el mejor equipo de tecnología de punta.</p>
          </li>
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de instrumento médico" src={require("../images/icons/medical-equipment.png")} style={{ height: "90px" }} className="inline-block" />
            <p className="mt-2">Incrementar los servicios de diagnostico médico.</p>
          </li>
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de precios bajos" src={require("../images/icons/cheap.png")} style={{ height: "90px" }} className="inline-block" />
            <p className="mt-2">Brindar servicios de laboratorio de análisis clínicos de calidad con costos accesibles a la mayoría de la población Chiapaneca.</p>
          </li>
          <li className=" w-1/2 sm:w-1/3">
            <img alt="Ilustración de diálogo" src={require("../images/icons/conversation.png")} style={{ height: "90px" }} className="inline-block" />
            <p className="mt-2">Mantener comunicación con todas las sucursales sobre servicios que otorgamos, de reportes y administración a través de un Software o programa de red (interfaz).</p>
          </li>
        </ul>

        <Title icon="fas fa-heart ">Valores</Title>
        <ul className="text-2xl flex flex-wrap justify-center text-center list-none">
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Compromiso</li>
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Excelencia</li>
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Calidad</li>
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Responsabilidad</li>
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Lealtad</li>
          <li className="w-1/2 sm:w-1/4 md:w-1/5 p-4">Seguridad</li>
        </ul>           
        <Title icon="fas fa-file ">Certificaciones</Title>
        <Certificaciones />
        <CollapsableHistory />
      </div>
    </Container>
    <Footer />
  </Layout>
)